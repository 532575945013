import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  errorSearchCategoriesAction,
  responseCategorieAction,
  responseCategoriesAction,
  searchCategoriesAction,
  selectCategorieAction,
} from '../actions/categories.actions';
import {
  categorie,
  responseCategories,
  searchCategories,
} from '../interfaces/categories.interface';
import { CategoriesService } from '../services/categories.service';

@Injectable()
export class CategoriesEffects {
  fetchCategoriesEffects$ = createEffect(() =>
    this.action$.pipe(
      ofType(searchCategoriesAction),
      switchMap((search: searchCategories) =>
        this.categorieService.fetchCategories(search).pipe(
          map((responseCategories: responseCategories) =>
            responseCategoriesAction(responseCategories)
          ),
          catchError((error) => of(errorSearchCategoriesAction({ error })))
        )
      )
    )
  );

  fetchCategorieEffects$ = createEffect(() =>
    this.action$.pipe(
      ofType(selectCategorieAction),
      switchMap((slug: { slug: string }) =>
        this.categorieService.fetchCategorieSlug(slug.slug).pipe(
          map((categorie: categorie) => responseCategorieAction(categorie)),
          catchError((error) => of(errorSearchCategoriesAction({ error })))
        )
      )
    )
  );

  constructor(
    private readonly action$: Actions,
    private readonly categorieService: CategoriesService
  ) {}
}
