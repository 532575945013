import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { searchProduits } from '../stores/interfaces/produits.interface';
import { searchProduit } from '../stores/selectors/produits.selectors';
import { getLink } from '../stores/utils';
import { Subscription } from 'rxjs';
import {
  searchCategoriesAction,
  selectCategorieAction,
} from '../stores/actions/categories.actions';
import { selectCategorie } from '../stores/selectors/categories.selectors';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  breadcrumbs: searchProduits = {};
  searchs: string[] = [];
  isActive: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store, private router: Router) {
    const search = this.store.select(searchProduit).subscribe((s) => {
      this.searchs = s.search ? s.search.split('%2B') : [];
      this.breadcrumbs = { ...s };
      this.getCategorie();
      this.isActive = Object.keys(this.breadcrumbs).length > 0;
    });
    this.subscriptions.push(search);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async getCategorie() {
    if (this.breadcrumbs.categorie) {
      this.store.dispatch(
        selectCategorieAction({ slug: this.breadcrumbs.categorie })
      );
    }
    this.store.select(selectCategorie).subscribe((c) => {
      this.breadcrumbs.categorie = c.name;
      this.breadcrumbs.slugCategorie = c.slug;
    });
  }

  remove(chip: string, type: 'destination' | 'slugCategorie' | 'tag' | 'search') {
    this.breadcrumbs[type] = '';
    if (type === 'search') {
      this.searchs.splice(this.searchs.indexOf(chip), 1);
    }
    this.router
      .navigateByUrl(
        `${getLink(
          this.breadcrumbs.destination,
          this.breadcrumbs.slugCategorie,
          this.breadcrumbs.tag,
          this.searchs.join('+')
        )}`
      )
      .then(() => window.location.reload());
  }
}
