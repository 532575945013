import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { selectDrawer } from '../stores/selectors/site.selectors';
import { CategoriesService } from '../stores/services/categories.service';
import { MENU } from './liste-link';
import { onDrawer } from '../stores/actions/site.actions';
import { Subscription } from 'rxjs';

interface menu {
  icon: string;
  label: string;
  path?: string;
  roles?: string[];
  submenu: menu[];
}

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent {
  menus: menu[] = MENU;
  niv: string = '';
  niv1: string = '';
  burger: boolean = false;
  titre: string = 'sechapper.com';
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private titleService: Title,
    private readonly categorieService: CategoriesService
  ) {
    const drawer = this.store.select(selectDrawer).subscribe((r) => {
      this.burger = r;
      if (!r) {
        this.niv = '';
        this.niv1 = '';
      }
    });
    this.menus[1].submenu = [];
    const menu = this.categorieService.menuCategorie().subscribe((m) => {
      m.map((menu) => {
        this.menus[1].submenu = [
          ...this.menus[1].submenu,
          {
            icon: '',
            label: `${menu.categorie[0].name} (${menu.count})`,
            path: `/c/${menu.categorie[0].slug}`,
            submenu: [],
          },
        ];
      });
    });
    this.subscriptions.push(drawer);
    this.subscriptions.push(menu);
  }

  setTitle(titre: string) {
    this.titleService.setTitle(`${titre} - ${this.titre}`);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  openSidebar(niv: string) {
    this.store.dispatch(onDrawer());
    this.niv1 = '';
    this.niv = this.niv !== niv ? niv : '';
  }
  openSidebar1(niv1: string) {
    this.store.dispatch(onDrawer());
    this.niv1 = this.niv1 !== niv1 ? niv1 : '';
  }
  openDrawer() {
    let twist = this.burger ? 'open' : '';
    if (!this.burger) {
      twist = this.niv ? 'open' : '';
    }
    return twist;
  }
}
