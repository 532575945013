export const MENU = [
  {
    icon: 'public',
    label: 'Destinations',
    submenu: [
      {
        icon: '',
        label: 'France',
        path: '/',
        submenu: [
          {
            icon: '',
            label: 'Paris',
            path: '/d/paris',
            submenu: [],
          },
          {
            icon: '',
            label: 'Marseille',
            path: '/d/marseille',
            submenu: [],
          },
          { icon: '', label: 'Lyon', path: '/d/lyon', submenu: [] },
          {
            icon: '',
            label: 'Aix-en-Provence',
            path: '/d/aix-en-provence',
            submenu: [],
          },
          {
            icon: '',
            label: 'Bordeaux',
            path: '/d/bordeaux',
            submenu: [],
          },
          {
            icon: '',
            label: 'Toulouse',
            path: '/d/toulouse',
            submenu: [],
          },
          {
            icon: '',
            label: 'Corse',
            path: '/d/corse',
            submenu: [],
          },
          {
            icon: '',
            label: 'Strasbourg',
            path: '/d/strasbourg',
            submenu: [],
          },
          {
            icon: '',
            label: 'Rouen',
            path: '/d/rouen',
            submenu: [],
          },
          {
            icon: '',
            label: 'Rennes',
            path: '/d/rennes',
            submenu: [],
          },
          {
            icon: '',
            label: 'Saint-Malo',
            path: '/d/saint-malo',
            submenu: [],
          },
          {
            icon: '',
            label: 'Vannes',
            path: '/d/vannes',
            submenu: [],
          },
          {
            icon: '',
            label: 'Biarritz',
            path: '/d/biarritz',
            submenu: [],
          },
          {
            icon: '',
            label: 'Hossegor',
            path: '/d/hossegor',
            submenu: [],
          },
          {
            icon: '',
            label: 'Cannes',
            path: '/d/cannes',
            submenu: [],
          },
          {
            icon: '',
            label: 'Carcassonne',
            path: '/d/carcassonne',
            submenu: [],
          },
          {
            icon: '',
            label: 'Perpignan',
            path: '/d/perpignan',
            submenu: [],
          },
          {
            icon: '',
            label: 'Saint-Raphael',
            path: '/d/saint',
            submenu: [],
          },
          {
            icon: '',
            label: 'Arles',
            path: '/d/arles',
            submenu: [],
          },
          {
            icon: '',
            label: 'Beaune',
            path: '/d/beaune',
            submenu: [],
          },
          {
            icon: '',
            label: 'Dijon',
            path: '/d/dijon',
            submenu: [],
          },
          {
            icon: '',
            label: 'Tours',
            path: '/d/tours',
            submenu: [],
          },
          {
            icon: '',
            label: 'Poitiers',
            path: '/d/poitiers',
            submenu: [],
          },
          {
            icon: '',
            label: 'Orléans',
            path: '/d/orleans',
            submenu: [],
          },
          {
            icon: '',
            label: 'La Baule',
            path: '/d/la-baule',
            submenu: [],
          },
          {
            icon: '',
            label: 'Courchevel',
            path: '/d/courchevel',
            submenu: [],
          },
          {
            icon: '',
            label: 'Avignon',
            path: '/d/avignon',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Italie',
        path: '/d/italie',
        submenu: [
          {
            icon: '',
            label: 'Rome',
            path: '/d/rome',
            submenu: [],
          },
          {
            icon: '',
            label: 'Florence',
            path: '/d/florence',
            submenu: [],
          },
          {
            icon: '',
            label: 'Venise',
            path: '/d/venise',
            submenu: [],
          },
          {
            icon: '',
            label: 'Pise',
            path: '/d/pise',
            submenu: [],
          },
          {
            icon: '',
            label: 'Toscane',
            path: '/d/toscane',
            submenu: [],
          },
          {
            icon: '',
            label: 'Naples',
            path: '/d/naples',
            submenu: [],
          },
          {
            icon: '',
            label: 'Sicile',
            path: '/d/sicile',
            submenu: [],
          },
          {
            icon: '',
            label: 'Sardaigne',
            path: '/d/sardaigne',
            submenu: [],
          },
          {
            icon: '',
            label: 'Turin',
            path: '/d/turin',
            submenu: [],
          },
          {
            icon: '',
            label: 'Sienne',
            path: '/d/sienne',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Espagne',
        path: '/d/espagne',
        submenu: [
          {
            icon: '',
            label: 'Barcelone',
            path: '/d/barcelone',
            submenu: [],
          },
          {
            icon: '',
            label: 'Madrid',
            path: '/d/madrid',
            submenu: [],
          },
          {
            icon: '',
            label: 'Séville',
            path: '/d/seville',
            submenu: [],
          },
          {
            icon: '',
            label: 'Ibiza',
            path: '/d/ibiza',
            submenu: [],
          },
          {
            icon: '',
            label: 'Tolède',
            path: '/d/tolede',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Portugal',
        path: '/d/portugal',
        submenu: [
          {
            icon: '',
            label: 'Lisbonne',
            path: '/d/lisbonne',
            submenu: [],
          },
          {
            icon: '',
            label: 'Porto',
            path: '/d/porto',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Belgique',
        path: '/d/belgique',
        submenu: [
          {
            icon: '',
            label: 'Bruges',
            path: '/d/bruges',
            submenu: [],
          },
          {
            icon: '',
            label: 'Bruxelles',
            path: '/d/bruxelles',
            submenu: [],
          },
          {
            icon: '',
            label: 'Anvers',
            path: '/d/anvers',
            submenu: [],
          },
          {
            icon: '',
            label: 'Gand',
            path: '/d/gand',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Maroc',
        path: '/d/maroc',
        submenu: [
          {
            icon: '',
            label: 'Fès',
            path: '/d/fes',
            submenu: [],
          },
          {
            icon: '',
            label: 'Essaouira',
            path: '/d/essaouira',
            submenu: [],
          },
          {
            icon: '',
            label: 'Ouarzazate',
            path: '/d/ouarzazate',
            submenu: [],
          },
          {
            icon: '',
            label: 'Casablanca',
            path: '/d/casablanca',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Grèce',
        path: '/d/grece',
        submenu: [
          {
            icon: '',
            label: 'Athènes',
            path: '/d/athenes',
            submenu: [],
          },
          {
            icon: '',
            label: 'Mykonos',
            path: '/d/mykonos',
            submenu: [],
          },
          {
            icon: '',
            label: 'Santorin',
            path: '/d/santorin',
            submenu: [],
          },
          {
            icon: '',
            label: 'Rhodes',
            path: '/d/rhodes',
            submenu: [],
          },
          {
            icon: '',
            label: 'Corfou',
            path: '/d/corfou',
            submenu: [],
          },
          {
            icon: '',
            label: 'Paros',
            path: '/d/paros',
            submenu: [],
          },
          {
            icon: '',
            label: 'Naxos',
            path: '/d/naxos',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Etats-Unis',
        path: '/d/etats-unis',
        submenu: [
          {
            icon: '',
            label: 'New-York',
            path: '/d/new-york',
            submenu: [],
          },
          {
            icon: '',
            label: 'San Francisco',
            path: '/d/san-francisco',
            submenu: [],
          },
          {
            icon: '',
            label: 'Miami',
            path: '/d/miami',
            submenu: [],
          },
          {
            icon: '',
            label: 'Chicago',
            path: '/d/chicago',
            submenu: [],
          },
          {
            icon: '',
            label: 'Los Angeles',
            path: '/d/los-angeles',
            submenu: [],
          },
          {
            icon: '',
            label: 'Philadelphie',
            path: '/d/philadelphie',
            submenu: [],
          },
          {
            icon: '',
            label: 'Washington',
            path: '/d/washington',
            submenu: [],
          },
          {
            icon: '',
            label: 'Boston',
            path: '/d/boston',
            submenu: [],
          },
          {
            icon: '',
            label: 'Dallas',
            path: '/d/dallas',
            submenu: [],
          },
          {
            icon: '',
            label: 'Californie',
            path: '/d/californie',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Thaïlande',
        path: '/d/thailande',
        submenu: [
          {
            icon: '',
            label: 'Bangkok',
            path: '/d/bangkok',
            submenu: [],
          },
          {
            icon: '',
            label: 'Chiang Mai',
            path: '/d/chiang-mai',
            submenu: [],
          },
          {
            icon: '',
            label: 'Phuket',
            path: '/d/phuket',
            submenu: [],
          },
          {
            icon: '',
            label: 'Koh Samui',
            path: '/d/koh-samui',
            submenu: [],
          },
          {
            icon: '',
            label: 'Koh Lanta',
            path: '/d/koh-lanta',
            submenu: [],
          },
          {
            icon: '',
            label: 'Chiang Rai',
            path: '/d/chiang-rai',
            submenu: [],
          },
          {
            icon: '',
            label: 'Pai',
            path: '/d/pai',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Croatie',
        path: '/d/croatie',
        submenu: [
          {
            icon: '',
            label: 'Zagreb',
            path: '/d/zagreb',
            submenu: [],
          },
          {
            icon: '',
            label: 'Split',
            path: '/d/split',
            submenu: [],
          },
          {
            icon: '',
            label: 'Dubrovnik',
            path: '/d/dubrovnik',
            submenu: [],
          },
          {
            icon: '',
            label: 'Pula',
            path: '/d/pula',
            submenu: [],
          },
          {
            icon: '',
            label: 'Zadar',
            path: '/d/zadar',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Vietnam',
        path: '/d/vietnam',
        submenu: [
          {
            icon: '',
            label: 'Ho Chi Minh Ville',
            path: '/d/ho-chi-minh-ville',
            submenu: [],
          },
          {
            icon: '',
            label: 'Hanoi',
            path: '/d/hanoi',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Chine',
        path: '/d/chine',
        submenu: [
          {
            icon: '',
            label: 'Pékin',
            path: '/d/pekin',
            submenu: [],
          },
          {
            icon: '',
            label: 'Shanghai',
            path: '/d/shanghai',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Russie',
        path: '/d/russie',
        submenu: [
          {
            icon: '',
            label: 'Saint-Petersbourg',
            path: '/d/saint-petersbourg',
            submenu: [],
          },
          {
            icon: '',
            label: 'Moscou',
            path: '/d/moscou',
            submenu: [],
          },
        ],
      },
      {
        icon: '',
        label: 'Allemagne',
        path: '/d/allemagne',
        submenu: [
          {
            icon: '',
            label: 'Berlin',
            path: '/d/berlin',
            submenu: [],
          },
          {
            icon: '',
            label: 'Munich',
            path: '/d/munich',
            submenu: [],
          },
          {
            icon: '',
            label: 'Nuremberg',
            path: '/d/nuremberg',
            submenu: [],
          },
          {
            icon: '',
            label: 'Francfort',
            path: '/d/francfort',
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    icon: 'category',
    label: 'Catégories',
    submenu: [],
  },
  {
    icon: 'dynamic_feed',
    label: 'Référencer votre offre',
    path: '/referencer-votre-offre',
    submenu: [],
  },
  { icon: 'info', label: 'À propos', path: '/a-propos', submenu: [] },
  {
    icon: 'account_balance',
    label: 'Mentions légales',
    path: '/mentions-legales',
    submenu: [],
  },
];
