import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { offDrawer, onDrawer } from './stores/actions/site.actions';
import {
  activeDrawerFeature,
  activeSpinnerFeature,
} from './stores/selectors/site.selectors';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  spinner: boolean = false;
  drawer: boolean = false;
  titre: string = '';
  previousUrl: string = '';
  currentUrl: string = '';
  logo: string = '';

  constructor(
    private titleService: Title,
    private store: Store,
    private router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.logo = environment.logo;
    if (isPlatformBrowser(this._platformId)) {
      sessionStorage.setItem('currentUrl', this.router.url);
      
      const canonical = this.document.createElement('link');
      canonical.setAttribute('rel', 'canonical');
      canonical.setAttribute('href', this.document.URL);
      this.document.head.append(canonical);
    }
    this.titleService.setTitle(this.titre);
    this.store.select(activeSpinnerFeature).subscribe((r) => {
      this.spinner = r.activeSpinner;
    });
    this.store.select(activeDrawerFeature).subscribe((r) => {
      this.drawer = r.drawer;
    });
  }

  close() {
    this.store.dispatch(offDrawer());
  }

  drawerToggle() {
    this.drawer
      ? this.store.dispatch(offDrawer())
      : this.store.dispatch(onDrawer());
  }
}
