import { createAction, props } from '@ngrx/store';
import {
  categorie, responseCategories, searchCategories,
} from '../interfaces/categories.interface';

export const searchCategoriesAction = createAction(
  '[Search Categories]',
  props<searchCategories>()
);
export const resetSearchCategoriesAction = createAction(
  '[Reset Search Categories]'
);
export const responseCategoriesAction = createAction(
  '[Response Search Categories]',
  props<responseCategories>()
);
export const selectCategorieAction = createAction(
  '[Select Categorie]',
  props<{ slug: string }>()
);
export const responseCategorieAction = createAction(
  '[Response Select Categorie]',
  props<categorie>()
);
export const resetSelectCategorieAction = createAction(
  '[Reset Select Categorie]'
);
export const resetResponseCategoriesAction = createAction(
  '[Reset Response Categories]'
);
export const errorSearchCategoriesAction = createAction(
  '[Error Search Categories]',
  props<{ error: any }>()
);
