import { Action, ActionReducerMap } from '@ngrx/store';
import {
  CONTENUDESTINATIONFEATUREKEY,
  ContenudestinationReducer,
  RESPONSECONTENUDESTINATIONFEATUREKEY,
  ResponseContenudestinationReducer,
  ResponseProduitReducer,
  ResponseProduitsReducer,
  ResponseAggregaProduitsReducer,
  RESPONSESEARCHPRODUITSFEATUREKEY,
  RESPONSESELECTPRODUITFEATUREKEY,
  RESPONSESEARCHAGREGATEPRODUITSFEATUREKEY,
  SEARCHPRODUITSFEATUREKEY,
  SearchProduitsReducer,
} from 'src/app/stores/reducers/produits.reducer';
import {
  categorie,
  responseCategories,
} from './interfaces/categories.interface';
import {
  aggregateProduitDestination,
  contenudestination,
  produit,
  responseContenudestination,
  responseProduits,
  searchProduits,
} from './interfaces/produits.interface';
import { infosite, search } from './interfaces/site.interface';
import {
  ResponseCategorieReducer,
  ResponseCategoriesReducer,
  RESPONSESEARCHCATEGORIESFEATUREKEY,
  RESPONSESELECTCATEGORIEFEATUREKEY,
} from './reducers/categories.reducer';
import {
  ACTIVEDRAWERKEY,
  ACTIVESPINNERKEY,
  SEARCHKEY,
  setSearchReducer,
  setStateSpinnerReducer,
} from './reducers/site.reducer';

export interface AppState {
  responseCategories: responseCategories;
  categorie: categorie;
  responseProduits: responseProduits;
  responseAggregateProduits: aggregateProduitDestination;
  produit: produit;
  contenudestination: contenudestination;
  responseContenudestination: responseContenudestination;
  activeSpinner: infosite;
  activeDrawer: infosite;
  search: search;
  searchProduits: searchProduits;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState, Action> = {
  [SEARCHPRODUITSFEATUREKEY]: SearchProduitsReducer,
  [RESPONSESELECTPRODUITFEATUREKEY]: ResponseProduitReducer,
  [RESPONSESEARCHAGREGATEPRODUITSFEATUREKEY]: ResponseAggregaProduitsReducer,
  [RESPONSESEARCHPRODUITSFEATUREKEY]: ResponseProduitsReducer,
  [ACTIVESPINNERKEY]: setStateSpinnerReducer,
  [ACTIVEDRAWERKEY]: setStateSpinnerReducer,
  [SEARCHKEY]: setSearchReducer,
  [RESPONSESELECTCATEGORIEFEATUREKEY]: ResponseCategorieReducer,
  [RESPONSESEARCHCATEGORIESFEATUREKEY]: ResponseCategoriesReducer,
  [CONTENUDESTINATIONFEATUREKEY]: ContenudestinationReducer,
  [RESPONSECONTENUDESTINATIONFEATUREKEY]: ResponseContenudestinationReducer,
};
