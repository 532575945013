import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  aggregateProduitDestination,
  produit,
  responseContenudestination,
  responseProduits,
  searchProduits,
} from '../interfaces/produits.interface';
import {
  RESPONSECONTENUDESTINATIONFEATUREKEY,
  RESPONSESEARCHAGREGATEPRODUITSFEATUREKEY,
  RESPONSESEARCHPRODUITSFEATUREKEY,
  RESPONSESELECTPRODUITFEATUREKEY,
  SEARCHPRODUITSFEATUREKEY,
} from '../reducers/produits.reducer';

export const responseContenudestinationFeature =
  createFeatureSelector<responseContenudestination>(
    RESPONSECONTENUDESTINATIONFEATUREKEY
  );
export const searchProduitsFeature = createFeatureSelector<searchProduits>(
  SEARCHPRODUITSFEATUREKEY
);
export const responseProduitsFeature = createFeatureSelector<responseProduits>(
  RESPONSESEARCHPRODUITSFEATUREKEY
);
export const responseAgregateProduitsFeature =
  createFeatureSelector<aggregateProduitDestination>(
    RESPONSESEARCHAGREGATEPRODUITSFEATUREKEY
  );
export const responseProduitFeature = createFeatureSelector<produit>(
  RESPONSESELECTPRODUITFEATUREKEY
);

export const selectProduits = createSelector(
  responseProduitsFeature,
  (state: responseProduits) => state.produits
);

export const selectAgregateProduits = createSelector(
  responseAgregateProduitsFeature,
  (state: aggregateProduitDestination) => state
);

export const selectProduit = createSelector(
  responseProduitFeature,
  (state: produit) => state
);

export const searchProduit = createSelector(
  searchProduitsFeature,
  (state: searchProduits) => state
);

export const selectContenudestination = createSelector(
  responseContenudestinationFeature,
  (state: responseContenudestination) => state.contenudestination
);
