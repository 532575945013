<div class="layout">
  <aside id="sidebars" [class]="'sidebars ' + openDrawer()">
    <div class="sidebars-layout">
      <div class="sidebars-content">
        <nav class="menu open-current-submenu">
          <ul>
            <li *ngFor="let menu of menus" [class]="
                'menu-item sub-menu ' + (niv === menu.label ? 'open' : '')
              ">
              <span (click)="openSidebar(menu.label)" class="niv" *ngIf="menu.submenu.length">
                <span class="menu-icon">
                  <mat-icon>{{ menu.icon }}</mat-icon>
                </span>
                <span class="menu-title">{{ menu.label }}</span>
                <span class="menu-suffix"> &#9654;</span>
              </span>
              <a [href]="menu.path" class="niv" *ngIf="menu.submenu.length === 0" (click)="setTitle(menu.label)">
                <span class="menu-icon">
                  <mat-icon>{{ menu.icon }}</mat-icon>
                </span>
                <span class="menu-title">{{ menu.label }}</span>
              </a>
              <div class="sub-menu-list" *ngIf="menu.submenu">
                <ul>
                  <li *ngFor="let submenu of menu.submenu" [class]="
                      'menu-item sub-menu ' +
                      (niv1 === submenu.label ? 'open' : '')
                    ">
                    <span (click)="openSidebar1(submenu.label)" class="niv1" *ngIf="submenu.submenu.length>0">
                      <span class="menu-title">{{ submenu.label }}</span>
                      <span class="menu-suffix" *ngIf="submenu.submenu">
                        &#9654;</span>
                    </span>
                    <a [href]="submenu.path" (click)="openSidebar1(submenu.label)" class="niv1"
                      *ngIf="submenu.submenu.length===0" (click)="setTitle(submenu.label)">
                      <span class="menu-title">{{ submenu.label }}</span>
                      <span class="menu-suffix" *ngIf="submenu.submenu.length>0">
                        &#9654;</span>
                    </a>
                    <div class="sub-menu-list" *ngIf="menu.submenu">
                      <ul>
                        <li *ngFor="let subsubmenu of submenu.submenu" class="menu-item">
                          <a [href]="subsubmenu.path" class="niv2" (click)="setTitle(subsubmenu.label)">
                            <span class="menu-title">{{
                              subsubmenu.label
                              }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </aside>
</div>