import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  categorie,
  responseCategories,
} from '../interfaces/categories.interface';
import {
  RESPONSESEARCHCATEGORIESFEATUREKEY,
  RESPONSESELECTCATEGORIEFEATUREKEY,
} from '../reducers/categories.reducer';

export const responseCategoriesFeature =
  createFeatureSelector<responseCategories>(RESPONSESEARCHCATEGORIESFEATUREKEY);
export const responseCategorieFeature = createFeatureSelector<categorie>(
  RESPONSESELECTCATEGORIEFEATUREKEY
);

export const selectCategories = createSelector(
  responseCategoriesFeature,
  (state: responseCategories) => state
);

export const selectCategorie = createSelector(
  responseCategorieFeature,
  (state: categorie) => state
);
