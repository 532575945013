import { createReducer, on } from '@ngrx/store';
import {
  aggregateProduitDestination,
  classement,
  contenudestination,
  produit,
  responseContenudestination,
  responseProduits,
  searchProduits,
  totalProduits,
} from '../interfaces/produits.interface';

import * as produitsAction from '../actions/produits.actions';

export const SEARCH_PRODUIT_INIT_STATE: searchProduits = {
  destination: '',
  categorie: '',
  tag: '',
  search: '',
  take: 20,
  skip: 0,
};

export const CONTENUDESTINATION_INIT_STATE: contenudestination = {
  destination: '',
  titre: '',
  contenu: '',
};

export const RESPONSE_CONTENUDESTINATION_INIT_STATE: responseContenudestination =
  {
    contenudestination: [],
  };

export const RESPONSE_PRODUITS_INIT_STATE: responseProduits = {
  produits: [],
  take: 20,
  skip: 0,
};

export const RESPONSE_AGGREGA_PRODUITS_INIT_STATE: aggregateProduitDestination =
  {
    classement: [],
  };

export const TOTAL_PRODUITS_INIT_STATE: totalProduits = {
  total: 0,
};

export const RESPONSE_PRODUIT_INIT_STATE: produit = {
  _id: '',
  id: '',
  idProduit: '',
  dateDebut: '',
  dateFin: '',
  nomProduit: '',
  prix: '',
  urlProduit: '',
  image: '',
  promotion: '',
  description: '',
  tags: [],
  marchand: {
    name: '',
    logo: '',
  },
  nbFavoris: 0,
  categorie: '',
  nomContrat: '',
  destination: '',
  slug: '',
  parent: '',
  slugParent: '',
};

export const TOTALPRODUITSFEATUREKEY = 'totalProduits';
export const SEARCHPRODUITSFEATUREKEY = 'searchProduits';
export const RESPONSESEARCHPRODUITSFEATUREKEY = 'responseProduits';
export const RESPONSESEARCHAGREGATEPRODUITSFEATUREKEY =
  'responseAggregateProduits';
export const CONTENUDESTINATIONFEATUREKEY = 'contenudestination';
export const RESPONSECONTENUDESTINATIONFEATUREKEY =
  'responseContenudestination';
export const RESPONSESELECTPRODUITFEATUREKEY = 'produit';

export const TotlaProduitsReducer = createReducer(
  TOTAL_PRODUITS_INIT_STATE,
  on(
    produitsAction.totalProduitsAction,
    (state: totalProduits, { total }: totalProduits): totalProduits => {
      return { ...state, total };
    }
  ),
  on(produitsAction.resetTotalProduitsAction, (state) => {
    return { ...state, ...TOTAL_PRODUITS_INIT_STATE };
  })
);

export const SearchProduitsReducer = createReducer(
  SEARCH_PRODUIT_INIT_STATE,
  on(
    produitsAction.searchProduitsAction,
    (
      state: searchProduits,
      { destination, categorie, tag, search }: searchProduits
    ): searchProduits => {
      return { ...state, destination, categorie, tag, search };
    }
  ),
  on(produitsAction.resetSearchProduitsAction, (state) => {
    return { ...state, ...SEARCH_PRODUIT_INIT_STATE };
  })
);

export const ContenudestinationReducer = createReducer(
  CONTENUDESTINATION_INIT_STATE,
  on(
    produitsAction.searchContenudestinationAction,
    (
      state: contenudestination,
      { destination }: contenudestination
    ): contenudestination => {
      return { ...state, destination };
    }
  ),
  on(produitsAction.resetSearchContenudestinationAction, (state) => {
    return { ...state, ...CONTENUDESTINATION_INIT_STATE };
  })
);

export const ResponseContenudestinationReducer = createReducer(
  RESPONSE_CONTENUDESTINATION_INIT_STATE,
  on(
    produitsAction.responseContenudestinationAction,
    (
      state: responseContenudestination,
      response: responseContenudestination
    ): responseContenudestination => {
      return { ...state, ...response };
    }
  ),
  on(produitsAction.resetSearchContenudestinationAction, (state) => {
    return { ...state, ...RESPONSE_CONTENUDESTINATION_INIT_STATE };
  })
);

export const ResponseProduitsReducer = createReducer(
  RESPONSE_PRODUITS_INIT_STATE,
  on(
    produitsAction.responseProduitsAction,
    (state: responseProduits, response: responseProduits): responseProduits => {
      return { ...state, ...response };
    }
  ),
  on(produitsAction.resetResponseProduitsAction, (state) => {
    return { ...state, ...RESPONSE_PRODUITS_INIT_STATE };
  })
);

export const ResponseAggregaProduitsReducer = createReducer(
  RESPONSE_AGGREGA_PRODUITS_INIT_STATE,
  on(
    produitsAction.responseAggregateProduitsAction,
    (
      state: aggregateProduitDestination,
      response: aggregateProduitDestination
    ): aggregateProduitDestination => {
      return { ...state, ...response };
    }
  ),
  on(produitsAction.resetResponseAggregateProduitsAction, (state) => {
    return { ...state, ...RESPONSE_AGGREGA_PRODUITS_INIT_STATE };
  })
);

export const ResponseProduitReducer = createReducer(
  RESPONSE_PRODUIT_INIT_STATE,
  on(
    produitsAction.responseProduitAction,
    (state: produit, produit: produit): produit => {
      return { ...state, ...produit };
    }
  ),
  on(produitsAction.resetSelectProduitAction, (state) => {
    return { ...state, ...RESPONSE_PRODUIT_INIT_STATE };
  })
);
