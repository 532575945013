import { createReducer, on } from '@ngrx/store';
import { infosite, search } from '../interfaces/site.interface';
import * as siteAction from '../actions/site.actions';
export const ACTIVE_SPINNER_INIT: infosite = {
  activeSpinner: false,
  drawer: false,
};

export const SEARCH_INIT: search = {};

export const ACTIVESPINNERKEY = 'activeSpinner';
export const ACTIVEDRAWERKEY = 'activeDrawer';
export const SEARCHKEY = 'search';

export const setSearchReducer = createReducer(
  SEARCH_INIT,
  on(siteAction.searchAction, (state) => ({
    ...state,
    destination: state.destination,
    categorie: state.categorie,
    tag: state.tag,
    search: state.search,
  }))
);

export const setStateSpinnerReducer = createReducer(
  ACTIVE_SPINNER_INIT,
  on(siteAction.onDrawer, (state) => ({
    ...state,
    drawer: true,
  })),
  on(siteAction.offDrawer, (state) => ({
    ...state,
    drawer: false,
  })),
  on(siteAction.onSpinner, (state) => ({
    ...state,
    activeSpinner: true,
  })),
  on(siteAction.offSpinner, (state) => ({
    ...state,
    activeSpinner: false,
  }))
);
