import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  categorie,
  menuCategorie,
  responseCategories,
  searchCategories,
} from '../interfaces/categories.interface';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private http: HttpClient) {}

  public fetchCategories(
    requete: searchCategories
  ): Observable<responseCategories> {
    let link = `${environment.url_server}/categories/aggregate`;
    link += '/' + (requete.destination ? requete.destination : null);
    link += '/' + (requete.categorie ? requete.categorie : null);
    link += '/' + (requete.tag ? requete.tag : null);
    link += '/' + (requete.search ? requete.search : null);
    const reponse = this.http.get<responseCategories>(link);
    return reponse;
  }

  public fetchCategorie(id: string): Observable<categorie> {
    return this.http.get<categorie>(
      `${environment.url_server}/categories/${id}`
    );
  }

  public fetchCategorieSlug(slug: string): Observable<categorie> {
    return this.http.get<categorie>(
      `${environment.url_server}/categories/slug/${slug}`
    );
  }

  public menuCategorie(): Observable<menuCategorie[]> {
    return this.http.get<menuCategorie[]>(
      `${environment.url_server}/categories/menu/all`
    );
  }
}
