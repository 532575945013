export const environment = {
  production: true,
  url_server: 'https://www.sechapper.com/api',
  logo: 'https://www.sechapper.com:/assets/logos',
  csv: 'https://www.sechapper.com/assets/csv',
  icones: 'https://www.sechapper.com/assets/icones',
  // url_server: 'https://recette.sechapper.com/api',
  // logo: 'https://recette.sechapper.com:/assets/logos',
  // csv: 'https://recette.sechapper.com/assets/csv',
  // icones: 'https://recette.sechapper.com/assets/icones',
};
