import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { searchAction } from '../stores/actions/site.actions';
import { getLink } from '../stores/utils';
import { searchProduit } from '../stores/selectors/produits.selectors';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  searchs: string[] = [];
  searchCtrl = new FormControl('');
  destination: string = '';
  categorie: string = '';
  tag: string = '';
  search: string = '';

  constructor(private store: Store, private router: Router) {
    this.store.select(searchProduit).subscribe((s) => {
      this.destination = s.destination ?? '';
      this.categorie = s.categorie ?? '';
      this.tag = s.tag ?? '';
      this.searchs = s.search ? s.search.split('%2B') : [];
    });
  }

  add() {
    const value = (this.searchCtrl.value || '').trim();
    if (value !== '') {
      this.searchs = [...this.searchs, value];
    }
    this.searchCtrl.setValue('');
    this.router
      .navigateByUrl(
        getLink(
          this.destination,
          this.categorie,
          this.tag,
          this.searchs.join('+')
        )
      )
      .then(() => window.location.reload());
  }
}
