import { searchProduits } from './interfaces/produits.interface';
import { search } from './interfaces/site.interface';

export function getLink(
  destination: string = '',
  categorie: string = '',
  tag: string = '',
  search: string = ''
): string {
  let url = '';
  url += destination ? `/d/${destination}` : '';
  url += categorie ? `/c/${categorie}` : '';
  url += tag ? `/t/${tag}` : '';
  url += search ? `/s/${search}` : '';
  return url ?? '/';
}

export function linkToObject(link: string): searchProduits {
  const l = link.split('/').filter(Boolean);
  const result: Record<string, string> = {};
  for (let i = 0; i < l.length; i += 2) {
    const key = convertSearch(l[i]);
    const value = l[i + 1];
    result[key] = decodeURI(value);
  }
  return result;
}

export function convertSearch(v: string): string {
  const conversionMap: Record<string, string> = {
    d: 'destination',
    c: 'categorie',
    t: 'tag',
    s: 'search',
  };

  return conversionMap[v] || '';
}
