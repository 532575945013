<div id="breadcrumb" *ngIf="isActive">
  <mat-chip-listbox aria-label="breadcrumbs">
    <mat-chip (removed)="remove(breadcrumbs.destination, 'destination')" *ngIf="breadcrumbs.destination">
      {{ breadcrumbs.destination }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <mat-chip (removed)="remove(breadcrumbs.slugCategorie, 'slugCategorie')" *ngIf="breadcrumbs.slugCategorie">
      {{ breadcrumbs.categorie}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <mat-chip (removed)="remove(breadcrumbs.tag, 'tag')" *ngIf="breadcrumbs.tag">
      {{ breadcrumbs.tag }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <span *ngIf="breadcrumbs.search">
      <mat-chip (removed)="remove(search, 'search')" *ngFor="let search of searchs">
        {{ search }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </span>
  </mat-chip-listbox>
</div>