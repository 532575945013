import { createReducer, on } from '@ngrx/store';
import {
  categorie,
  responseCategories,
  searchCategories,
} from '../interfaces/categories.interface';

import * as categoriesAction from '../actions/categories.actions';

export const SEARCH_CATEGORIE_INIT_STATE: searchCategories = {
  search: '',
  take: 10,
  skip: 0,
};

export const RESPONSE_CATEGORIES_INIT_STATE: responseCategories = {
  categories: [],
  total: 0,
};

export const RESPONSE_CATEGORIE_INIT_STATE: categorie = {
  id: '',
  name: '',
  slug: '',
  rank: 0,
  description: '',
  icone: '',
};

export const SEARCHCATEGORIESFEATUREKEY = 'searchCategories';
export const RESPONSESEARCHCATEGORIESFEATUREKEY = 'responseCategories';
export const RESPONSESELECTCATEGORIEFEATUREKEY = 'categorie';

export const SearchCategoriesReducer = createReducer(
  SEARCH_CATEGORIE_INIT_STATE,
  on(
    categoriesAction.searchCategoriesAction,
    (
      state: searchCategories,
      { search }: searchCategories
    ): searchCategories => {
      return { ...state, search };
    }
  ),
  on(categoriesAction.resetSearchCategoriesAction, (state) => {
    return { ...state, ...SEARCH_CATEGORIE_INIT_STATE };
  })
);

export const ResponseCategoriesReducer = createReducer(
  RESPONSE_CATEGORIES_INIT_STATE,
  on(
    categoriesAction.responseCategoriesAction,
    (
      state: responseCategories,
      response: responseCategories
    ): responseCategories => {
      return { ...state, ...response };
    }
  ),
  on(categoriesAction.resetResponseCategoriesAction, (state) => {
    return { ...state, ...RESPONSE_CATEGORIES_INIT_STATE };
  })
);

export const ResponseCategorieReducer = createReducer(
  RESPONSE_CATEGORIE_INIT_STATE,
  on(
    categoriesAction.responseCategorieAction,
    (state: categorie, categorie: categorie): categorie => {
      return { ...state, ...categorie };
    }
  ),
  on(categoriesAction.resetSelectCategorieAction, (state) => {
    return { ...state, ...RESPONSE_CATEGORIE_INIT_STATE };
  })
);
