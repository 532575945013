import { isDevMode, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { DrawerComponent } from './drawer/drawer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { ROOT_REDUCERS } from './stores';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { ProduitsEffects } from './stores/effects/produits.effects';
import { CategoriesEffects } from './stores/effects/categories.effects';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    DrawerComponent,
    BreadcrumbComponent,
    SearchComponent,
    PageNotFoundComponent,
  ],
  imports: [
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(ROOT_REDUCERS),
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    EffectsModule.forRoot(),
    EffectsModule.forFeature([ProduitsEffects, CategoriesEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
    NgOptimizedImage,
    TransferHttpCacheModule,
  ],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
