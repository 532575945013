import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  aggregateProduitDestination,
  produit,
  responseContenudestination,
  responseProduits,
  searchProduits,
  statistique,
} from '../interfaces/produits.interface';

@Injectable({
  providedIn: 'root',
})
export class ProduitsService {
  constructor(private http: HttpClient) {}

  public fetchProduits(
    requete: searchProduits
  ): Observable<responseProduits | aggregateProduitDestination> {
    let values: any;
    let link = `${environment.url_server}/produits`;

    const homepage =
      requete.destination === '' &&
      requete.categorie === '' &&
      requete.tag === '' &&
      requete.search === '';

    const destinationpage =
      requete.destination !== '' &&
      requete.categorie === '' &&
      requete.tag === '' &&
      requete.search === '';

    if (homepage) {
      values = this.http.get<aggregateProduitDestination>(link);
    } else if (destinationpage) {
      link += `/d/${requete.destination}`;
      link += requete.search ? `/s/${requete.search}` : '';
      values = this.http.get<aggregateProduitDestination>(link);
    } else {
      link += requete.destination ? `/d/${requete.destination}` : '';
      link += requete.categorie ? `/c/${requete.categorie}` : '';
      link += requete.tag ? `/t/${requete.tag}` : '';
      link += requete.search ? `/s/${requete.search}` : '';
      values = this.http.get<responseProduits>(
        `${link}/${requete.take}/${requete.skip}`
      );
    }
    return values;
  }

  public fetchProduit(id: string): Observable<produit> {
    const res = this.http.get<produit>(
      `${environment.url_server}/produits/${id}`
    );
    res.subscribe((e) => {
      console.log(e);
    });
    return res;
  }

  public fetchContenudestination(
    destination: string
  ): Observable<responseContenudestination> {
    return this.http.get<responseContenudestination>(
      `${environment.url_server}/contenudestination/search/${destination}`
    );
  }

  public postStat({ ...statistique }: statistique) {
    this.http
      .get('https://api.ipify.org?format=json')
      .subscribe((data: any) => {
        this.http
          .post(`${environment.url_server}/statistiques`, {
            ...statistique,
            ip: data.ip,
          })
          .subscribe();
      });
  }
}
