import { createAction, props } from '@ngrx/store';
import { search } from '../interfaces/site.interface';

export const onDrawer = createAction('[Drawer On]');
export const offDrawer = createAction('[Drawer Off]');

export const onSpinner = createAction('[Spinner On]');
export const offSpinner = createAction('[Spinner Off]');

export const searchAction = createAction('[Search]', props<search>());
