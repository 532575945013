// redirection.guard.ts

import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
class RedirectGuard {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const destination: string = next.queryParams['destination'];
    const categorie: string = next.queryParams['categorie'];
    const tag: string = next.queryParams['tag'];
    const search: string = next.queryParams['search'];
    const social: string = next.queryParams['social'];
    const tmpl: string = next.queryParams['tmpl'];
    let redirect = '';
    redirect += destination ? `/d/${destination.toLowerCase()}` : '';
    redirect += categorie ? `/c/${categorie.toLowerCase()}` : '';
    redirect += tag ? `/t/${tag.toLowerCase()}` : '';
    redirect += search ? `/s/${search.toLowerCase()}` : '';
    if (redirect) this.router.navigateByUrl(redirect);
    return !redirect;
  }
}

export const isRedirect: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(RedirectGuard).canActivate(route, state);
};
