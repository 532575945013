<div class="main">
  <mat-drawer-container class="drawer-container">
    <mat-toolbar class="main header mat-elevation-z5">
      <div>
        <mat-icon class="burger mobile" (click)="drawerToggle()">menu</mat-icon>
        <a href="/">
          <picture id="logo">
            <source [srcset]="logo + '/logo.webp'" type="image/webp" width="207" height="50" />
            <source [srcset]="logo + '/logo.png'" type="image/png" width="207" height="50" />
            <img [ngSrc]="logo + '/logo.png'" alt="logo sechapper.com" title="logo sechapper.com" width="207"
              height="50" priority />
          </picture>
          <img [ngSrc]="logo + '/logo-mobile.png'" alt="sechapper.com" id="logo-mobile" loading="eager" width="35"
            height="30" />
        </a>
      </div>
      <search></search>
      <div class="spacer230"></div>
    </mat-toolbar>
    <div id="container">
      <app-drawer></app-drawer>
      <div class="spinner-box" *ngIf="spinner">
        <div class="blue-orbit leo"></div>

        <div class="green-orbit leo"></div>

        <div class="red-orbit leo"></div>

        <div class="white-orbit w1 leo"></div>
        <div class="white-orbit w2 leo"></div>
        <div class="white-orbit w3 leo"></div>
      </div>
      <div (click)="close()">
        <breadcrumb></breadcrumb>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-drawer-container>
</div>