import { createAction, props } from '@ngrx/store';
import {
  aggregateProduitDestination,
  contenudestination,
  produit,
  responseContenudestination,
  responseProduits,
  searchProduits,
  statistique,
  totalProduits,
} from '../interfaces/produits.interface';

export const totalProduitsAction = createAction(
  '[Total Produits]',
  props<totalProduits>()
);
export const postStatProduitsAction = createAction(
  '[Post Stat]',
  props<statistique>()
);
export const resetTotalProduitsAction = createAction('[Reset Total Produits]');
export const searchProduitsAction = createAction(
  '[Search Produits]',
  props<searchProduits>()
);
export const resetSearchProduitsAction = createAction(
  '[Reset Search Produits]'
);
export const responseProduitsAction = createAction(
  '[Response Search Produits]',
  props<responseProduits>()
);
export const responseAggregateProduitsAction = createAction(
  '[Response Aggregate Search Produits]',
  props<aggregateProduitDestination>()
);
export const searchContenudestinationAction = createAction(
  '[Search Contenudestinations]',
  props<contenudestination>()
);
export const resetSearchContenudestinationAction = createAction(
  '[Reset Search Contenudestination]'
);
export const responseContenudestinationAction = createAction(
  '[Response Search Contenudestination]',
  props<responseContenudestination>()
);
export const selectProduitAction = createAction(
  '[Select Produit]',
  props<{ id: string }>()
);
export const responseProduitAction = createAction(
  '[Response Select Produit]',
  props<produit>()
);
export const resetSelectProduitAction = createAction('[Reset Select Produit]');
export const resetResponseProduitsAction = createAction(
  '[Reset Response Produits]'
);
export const resetResponseAggregateProduitsAction = createAction(
  '[Reset Agregate Response Produits]'
);
export const errorSearchProduitsAction = createAction(
  '[Error Search Produits]',
  props<{ error: any }>()
);
