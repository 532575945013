import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  errorSearchProduitsAction,
  postStatProduitsAction,
  responseAggregateProduitsAction,
  responseContenudestinationAction,
  responseProduitAction,
  responseProduitsAction,
  searchContenudestinationAction,
  searchProduitsAction,
  selectProduitAction,
} from '../actions/produits.actions';
import {
  aggregateProduitDestination,
  contenudestination,
  produit,
  responseContenudestination,
  responseProduits,
  searchProduits,
  statistique,
} from '../interfaces/produits.interface';
import { ProduitsService } from '../services/produits.service';
import { Router } from '@angular/router';

@Injectable()
export class ProduitsEffects {
  constructor(
    private readonly action$: Actions,
    private readonly produitService: ProduitsService,
    private readonly router: Router
  ) {}

  fetchProduitsEffects$ = createEffect(() =>
    this.action$.pipe(
      ofType(searchProduitsAction),
      switchMap((search: searchProduits) =>
        this.produitService.fetchProduits(search).pipe(
          tap((res) => {
            console.log(search);
            if (
              ('classement' in res && res.classement.length === 0) ||
              ('produits' in res &&
                res.produits.length === 0 &&
                search.skip &&
                search.skip === 0 &&
                search.page !== 'relativate')
            ) {
              this.router.navigate(['/404']);
            }
          }),
          map(
            (
              responseProduits: responseProduits | aggregateProduitDestination
            ) => this.switchResponse(responseProduits)
          ),
          catchError((error) => {
            this.router.navigate(['/404']);
            return of(errorSearchProduitsAction({ error }));
          })
        )
      )
    )
  );

  fetchContenudestinationEffects$ = createEffect(() =>
    this.action$.pipe(
      ofType(searchContenudestinationAction),
      switchMap((destination: contenudestination) =>
        this.produitService
          .fetchContenudestination(destination.destination)
          .pipe(
            map((contenudestination: responseContenudestination) =>
              responseContenudestinationAction(contenudestination)
            ),
            catchError((error) => of(errorSearchProduitsAction({ error })))
          )
      )
    )
  );

  fetchProduitEffects$ = createEffect(() =>
    this.action$.pipe(
      ofType(selectProduitAction),
      switchMap((id: { id: string }) =>
        this.produitService.fetchProduit(id.id).pipe(
          map((produit: produit) => responseProduitAction(produit)),
          catchError((error) => {
            console.log('la');
            this.router.navigate(['/404']);
            return of(errorSearchProduitsAction({ error }));
          })
        )
      )
    )
  );

  postProduitStat$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(postStatProduitsAction),
        tap((statistique: statistique) =>
          this.produitService.postStat(statistique)
        )
      ),
    { dispatch: false }
  );

  switchResponse(v: responseProduits | aggregateProduitDestination): Action {
    if ('classement' in v) {
      return responseAggregateProduitsAction(v);
    } else {
      return responseProduitsAction(v);
    }
  }
}
