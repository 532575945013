import { createFeatureSelector, createSelector } from '@ngrx/store';
import { infosite, search } from '../interfaces/site.interface';
import { ACTIVEDRAWERKEY, ACTIVESPINNERKEY, SEARCHKEY } from '../reducers/site.reducer';

export const activeSpinnerFeature =
  createFeatureSelector<infosite>(ACTIVESPINNERKEY);
export const activeDrawerFeature =
  createFeatureSelector<infosite>(ACTIVEDRAWERKEY);

export const searchFeature =
  createFeatureSelector<search>(SEARCHKEY);

export const selectProduits = createSelector(
  activeSpinnerFeature,
  (state: infosite) => state.activeSpinner
);
export const selectDrawer = createSelector(
  activeDrawerFeature,
  (state: infosite) => state.drawer
);

export const selectSearch = createSelector(
  searchFeature,
  (state: search) => state
);
